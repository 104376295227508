import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import SEO from '../../src/components/SEO';
import Home from '../../src/components/ptt/PTTHome';
import NavBar from '../../src/components/NavBar';
import {
  selectFirebaseAuthed,
  selectFirebaseAuthIsLoaded,
} from '../../src/selectors';

function PTTHome(props: any) {
  // SEO
  const metaTitle = 'PTT | 全站統計';
  const metaDescription = `
    最潮最先進的台灣社群網站資料分析平台
    功能包含: PTT(批踢踢) / Dcard(狄卡) 使用者 ID 搜尋/ 近期動態
    (推噓回文 發表文章 IP 熱點分析), 文章及版的詳細資料統整,
    以及強大的全站搜尋引擎
  `.replace(/\s/g, '');

  const { authIsLoaded, authed } = props;

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
      />
      <NavBar isFetching={!authIsLoaded} authed={authed} export>
        {!authIsLoaded ? <Loader active inline="centered" /> : <Home />}
      </NavBar>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  authIsLoaded: selectFirebaseAuthIsLoaded(state),
  authed: selectFirebaseAuthed(state),
});

export default connect(mapStateToProps)(PTTHome);
