import React, { Component } from 'react';
import Link from 'next/link';
import {
  Flag,
  Header,
  Icon,
  Label,
  List,
  ListItem,
  Pagination,
  Segment,
} from 'semantic-ui-react';

import CustomContainer from '../../common/CustomContainer';
import { formatDatetime } from '../../Utils';

export const Article = ({
  article,
  isMobile,
  showAuthor,
  showBoard,
  showPushStats,
}) => (
  <List.Item
    key={article.id}
    // TODO -> shouldn't hard code this path
    as={Link}
    href={`/ptt/article/${article.id}`}
  >
    {
      showBoard
      && (
        <List.Content floated="right">
          <Label
            size={isMobile ? 'tiny' : 'small'}
            color="teal"
          >
            {article.board.alias ?? article.board.title}
            {' '}
            (
            {' '}
            {article.board.title}
            {' '}
            )
          </Label>
        </List.Content>
      )
    }
    <List.Content style={{ paddingTop: '2px' }}>
      <List.Header>
        {article.title}
      </List.Header>
      <List.Description style={{ paddingTop: '4px' }}>
        <Icon name="calendar outline" />
        {formatDatetime(article.datetime)}
      </List.Description>
      {
        showAuthor && (
          <List.Description style={{ paddingTop: '4px' }}>
            <Icon name="spy" />
            {article.author_id}
          </List.Description>
        )
      }
      {
        article.code2 && (
          <List.Description style={{ paddingTop: '4px' }}>
            <Icon name="location arrow" />
            {article.city}
            {' '}
            (
            {article.country}
            {' '}
            <Flag name={article.code2} />
            )
          </List.Description>
        )
      }
      {
        !article.active && (
          <List.Description style={{ paddingTop: '4px' }}>
            <Icon name="trash" color="red" />
            <span style={{ color: 'red' }}>
              此文章已被刪除囉!!
            </span>
          </List.Description>
        )
      }
    </List.Content>
    {
      showPushStats && (
        <List.Content floated="right">
          <List horizontal>
            <ListItem>
              <Icon color="blue" name="thumbs outline up" />
              {article.num_likes}
            </ListItem>
            <ListItem>
              <Icon color="red" name="thumbs outline down" />
              {article.num_hates}
            </ListItem>
            <ListItem>
              <Icon color="yellow" name="arrow right" />
              {article.num_replies}
            </ListItem>
          </List>
        </List.Content>
      )
    }
  </List.Item>
);

export default class Articles extends Component {
  onPageChange = (e, { activePage }) => this.props.fetchFunc(activePage);

  render() {
    const {
      data,
      isFetching,
      isMobile,
      offset,
      pageSize,
      showAuthor = false,
      showBoard = false,
      showPushStats = false,
      title,
      total,
    } = this.props;

    const n = data.length;

    if (n === 0 && !isFetching) {
      return (null);
    }

    return (
      <CustomContainer>
        <Header as={isMobile ? 'h3' : 'h2'} attached="top">
          {title || '所有文章'}
        </Header>
        <Segment
          attached
          loading={isFetching}
        >
          <List
            animated
            selection
            divided
            relaxed
            size={isMobile ? 'medium' : 'large'}
          >
            {
              data.map((article) => (
                <Article
                  article={article}
                  isMobile={isMobile}
                  showAuthor={showAuthor}
                  showBoard={showBoard}
                  showPushStats={showPushStats}
                />
              ))
            }
          </List>
          {
            total > 1 && (
              <Pagination
                boundaryRange={isMobile ? 0 : 3}
                defaultActivePage={(offset / pageSize) + 1}
                ellipsisItem={isMobile ? null : '...'}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={total}
                disabled={isFetching}
                onPageChange={this.onPageChange}
              />
            )
          }
        </Segment>
      </CustomContainer>
    );
  }
}
