import React, { Component } from 'react';
import FuzzySearch from 'fuzzy-search';
import { Button, Input, Label } from 'semantic-ui-react';

import { BoardLink } from '../../Links';

const TOP_N_BOARDS = 20;

export default class Boards extends Component {
  state = {
    data: [],
    onlyTop: true,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data.length > 0 && prevState.data.length === 0) {
      return {
        data: nextProps.data,
        searcher: new FuzzySearch(nextProps.data, ['title', 'tag', 'alias', 'description'], {
          caseSensitive: false,
        }),
      };
    }

    return null;
  }

  onSearchChange(event) {
    const {
      searcher,
    } = this.state;

    const q = event.target.value;

    if (q) {
      const filteredBoards = searcher.search(q);
      this.setState({
        data: filteredBoards,
        onlyTop: false,
      });
    } else {
      this.setState({
        data: this.props.data,
        onlyTop: true,
      });
    }
  }

  render() {
    const {
      data,
      onlyTop,
    } = this.state;

    const {
      isMobile,
    } = this.props;

    return (
      <div>
        {
          !isMobile && (
            <Input
              fluid
              icon={{
                name: 'search',
                link: false,
              }}
              iconPosition="left"
              placeholder="搜尋板 ID / 名稱"
              onChange={(e) => this.onSearchChange(e)}
              style={{
                marginBottom: '1.5em',
              }}
            />
          )
        }
        <Label.Group color="blue">
          {
            data
              .slice(0, onlyTop ? TOP_N_BOARDS : data.length)
              .map((board) => (
                <Label as="a" key={board.id}>
                  <BoardLink id={board.id}>
                    {board.title}
                    {' '}
                    {board.alias ?? board.tag}
                  </BoardLink>
                </Label>
              ))
          }
        </Label.Group>
        {
          onlyTop && (
            <Button
              fluid
              basic
              onClick={() => this.setState({ onlyTop: false })}
            >
              看更多
            </Button>
          )
        }
      </div>
    );
  }
}
