import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Header,
  Icon,
  Popup,
  Segment,
  Statistic,
} from 'semantic-ui-react';

import * as actions from '../../../actions';
import { formatNumber, showMessage } from '../../Utils';

// Code splitting
import Boards from './Boards';
import CustomContainer from '../../common/CustomContainer';
import HotArticles from '../common/HotArticles';
import UserDistributionsBarChart from '../../Charts/UserDistributionsBarChart';
import UserDistributionsMap from '../../Charts/UserDistributionsMap';

const MESSAGES = {
  SHOW_DB_STATS: `
    目前PTTBrain資料庫統計
  `,
  SHOW_USER_GEO_DISTRIBUTION: `
    根據文章與推噓回文的IP位置,
    統計鄉民在世界各城市的分佈情形
  `,
  SHOW_BOARDS: `
    目前資料庫所有的版
  `,
  SHOW_ARTICLES: `
    3天內統計全站熱門文章
  `,
};

const DEFAULT_HOT_ARTICLES_SINCE_DAYS_AGO = 1;
const HOT_ARTICLES_PAGE_SIZE = 5;
const TOTAL_HOT_ARTICLES_PAGES = 20;

const STYLES = {
  QUESTION_ICON: {
    float: 'right',
    cursor: 'pointer',
    color: '#7f8c8d',
  },
};

class Home extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchHotArticles = async (offset, sinceDaysAgo) => {
    await this.props.getHotArticles(
      sinceDaysAgo,
      HOT_ARTICLES_PAGE_SIZE,
      offset,
    );
  }

  fetchData = async () => {
    const {
      articles,
      getBoards,
      getPttDBStats,
      getUserGeoDistributions,
    } = this.props;

    await Promise.all([
      getBoards(),
      getPttDBStats(),
      this.fetchHotArticles(
        articles.offset,
        articles.sinceDaysAgo,
      ),
      getUserGeoDistributions(),
    ]);
  }

  handleHotArticlesDaysAgoDropdown = (e, { value }) => {
    this.fetchHotArticles(0, value);
  }

  handleHotArticlesPagination = (e, { activePage }) => {
    this.fetchHotArticles(
      (activePage - 1) * HOT_ARTICLES_PAGE_SIZE,
      DEFAULT_HOT_ARTICLES_SINCE_DAYS_AGO,
    );
  }

  render() {
    const {
      articles,
      boards,
      dbStats,
      isMobile,
      locations,
    } = this.props;

    return (
      <div>
        <CustomContainer>
          <Header
            as={isMobile ? 'h3' : 'h2'}
            attached="top"
          >
            目前PTT資料庫
            <div
              style={STYLES.QUESTION_ICON}
            >
              <Popup
                trigger={<Icon name="question" size="small" />}
                content={MESSAGES.SHOW_DB_STATS}
                position="bottom right"
              />
            </div>
          </Header>
          <Segment
            attached
            padded
            loading={dbStats.isFetching}
          >
            <Statistic.Group
              size={isMobile ? 'tiny' : 'small'}
            >
              <Statistic color="pink">
                <Statistic.Label>文章數</Statistic.Label>
                <Statistic.Value>{formatNumber(dbStats.data.total_articles)}</Statistic.Value>
              </Statistic>
              <Statistic color="teal">
                <Statistic.Label>推噓回文數</Statistic.Label>
                <Statistic.Value>{formatNumber(dbStats.data.total_pushes)}</Statistic.Value>
              </Statistic>
              <Statistic color="blue">
                <Statistic.Label>ID 數</Statistic.Label>
                <Statistic.Value>{formatNumber(dbStats.data.total_users)}</Statistic.Value>
              </Statistic>
            </Statistic.Group>
          </Segment>
        </CustomContainer>
        <CustomContainer>
          <Header as={isMobile ? 'h3' : 'h2'} attached="top">
            所有板
            {' '}
            (
            {boards.data.length}
            )
            <div
              style={STYLES.QUESTION_ICON}
            >
              <Popup
                trigger={<Icon name="question" size="small" />}
                content={MESSAGES.SHOW_BOARDS}
                position="bottom right"
              />
            </div>
          </Header>
          <Segment
            attached
            padded
            loading={boards.isFetching}
          >
            <Boards
              data={boards.data}
              isMobile={isMobile}
            />
          </Segment>
        </CustomContainer>
        <HotArticles
          isMobile={isMobile}
          isFetching={articles.isFetching}
          data={articles.data}
          offset={articles.offset}
          total={TOTAL_HOT_ARTICLES_PAGES}
          pageSize={HOT_ARTICLES_PAGE_SIZE}
          daysAgo={articles.sinceDaysAgo}
          onPageChange={this.handleHotArticlesPagination}
          onChangeDaysAgoDropdown={this.handleHotArticlesDaysAgoDropdown}
        />
        <CustomContainer>
          <Header as={isMobile ? 'h3' : 'h2'} attached="top">
            鄉民熱點
            <div
              style={STYLES.QUESTION_ICON}
            >
              <Popup
                trigger={<Icon name="question" size="small" />}
                content={MESSAGES.SHOW_USER_GEO_DISTRIBUTION}
                position="bottom right"
              />
            </div>
          </Header>
          <Segment
            attached
            padded
            textAlign="left"
            loading={locations.isFetching}
          >
            <Grid
              centered
              stackable
              columns="equal"
            >
              <Grid.Column>
                <UserDistributionsMap
                  data={locations.data}
                  domain={[0, 5000]}
                  isMobile={isMobile}
                />
              </Grid.Column>
              <Grid.Column>
                <UserDistributionsBarChart
                  data={locations.data}
                  indexBy="city"
                  isMobile={isMobile}
                  keys={['count']}
                />
              </Grid.Column>
            </Grid>
          </Segment>
        </CustomContainer>
      </div>
    );
  }
}

// TODO -> need to use selectors
const mapStateToProps = (state) => ({
  articles: state.metric.ptt.hotArticles,
  boards: state.board.all,
  dbStats: state.metric.ptt.dbStats,
  isMobile: state.browser.lessThan.medium,
  locations: state.metric.ptt.userGeoDistributions,
});

export default connect(mapStateToProps, actions)(Home);
