import React from 'react';
import {
  Dropdown,
  Header,
  List,
  Pagination,
  Segment,
} from 'semantic-ui-react';

import CustomContainer from '../../common/CustomContainer';
import { Article } from './Articles';

const STYLES = {
  DROPDOWN: {
    float: 'right',
    cursor: 'pointer',
    color: '#7f8c8d',
  },
};

const DEFAULT_TIME_WINDOW_DROPDOWN_OPTIONS = [
  {
    key: '6 hours',
    text: '6 小時內',
    value: 0.25,
    content: '6 小時內',
  },
  {
    key: '12 hours',
    text: '12 小時內',
    value: 0.5,
    content: '12 小時內',
  },
  {
    key: 'today',
    text: '當日',
    value: 1,
    content: '當日',
  },
  {
    key: 'three days',
    text: '三日內',
    value: 3,
    content: '三日內',
  },
  {
    key: 'this week',
    text: '本週',
    value: 7,
    content: '本週',
  },
];

export default (props) => {
  const {
    isMobile,
    isFetching,
    data,
    offset,
    total,
    pageSize,
    daysAgo,
    onPageChange,
    onChangeDaysAgoDropdown,
  } = props;

  return (
    <CustomContainer>
      <Header as={isMobile ? 'h3' : 'h2'} attached="top">
        熱門文章
        <div
          style={STYLES.DROPDOWN}
        >
          <Dropdown
            inline
            options={DEFAULT_TIME_WINDOW_DROPDOWN_OPTIONS}
            defaultValue={daysAgo}
            onChange={onChangeDaysAgoDropdown}
          />
        </div>
      </Header>
      <Segment
        attached
        loading={isFetching}
      >
        <List
          animated
          divided
          relaxed
          selection
          size={isMobile ? 'medium' : 'large'}
        >
          {
            data.map((article) => (
              <Article
                article={article}
                isMobile={isMobile}
                showAuthor
                showBoard
                showPushStats
              />
            ))
          }
        </List>
        {
          total > 1 && (
            <Pagination
              boundaryRange={isMobile ? 0 : 3}
              defaultActivePage={1}
              ellipsisItem={isMobile ? null : '...'}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              totalPages={total}
              activePage={(offset / pageSize) + 1}
              onPageChange={onPageChange}
            />
          )
        }
      </Segment>
    </CustomContainer>
  );
};
